<template>
  <div>
    <a-form-item label="内部API服务器域名">
      <a-input
        v-decorator="['china_mobile_api_account_form.api_server_domain', {
          normalize: this.$lodash.trim,
          initialValue: chinaMobileApiAccountForm.api_server_domain,
          rules: [{ required: true, message: '请输入内部API服务器域名' },
                  { max: 50, message: '最多50个字符' }
          ]
        }]"
      />
    </a-form-item>

    <a-form-item label="使用中国移动接口">
      <a-checkbox
        :checked="isUseStandardApi"
        v-decorator="['china_mobile_api_account_form.is_use_standard_api', {
          initialValue: chinaMobileApiAccountForm.is_use_standard_api
        }]"
        @change="changeIsUseStandardApi"
      />
    </a-form-item>
    <!--              中国移动接口参数显示-->
    <div v-show="isUseStandardApi">
      <a-form-item label="中国移动接口版本">
        <a-select
          v-decorator="['china_mobile_api_account_form.standard_api_version', {
            initialValue: chinaMobileApiAccountForm.standard_api_version,
            rules: [{ required: isUseStandardApi, message: '请选择中国移动接口版本' }
            ]
          }]"
        >
          <a-select-option key="V5" value="V5">
            V5
          </a-select-option>
          <a-select-option key="V2" value="V2">
            V2
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="API访问路径">
        <a-input
          v-decorator="['china_mobile_api_account_form.standard_api_url', {
            normalize: this.$lodash.trim,
            initialValue: chinaMobileApiAccountForm.standard_api_url,
            rules: [{ required: isUseStandardApi, message: '请输入API访问路径' },
                    { max: 50, message: '最多50个字符' }
            ]
          }]"
        />
      </a-form-item>

      <a-form-item label="应用编码">
        <a-input
          v-decorator="['china_mobile_api_account_form.app_id', {
            normalize: this.$lodash.trim,
            initialValue: chinaMobileApiAccountForm.app_id,
            rules: [{ required: isUseStandardApi, message: '请输入应用编码' },
                    { max: 50, message: '最多50个字符' }
            ]
          }]"
        />
      </a-form-item>

      <a-form-item label="接口密码">
        <a-input
          v-decorator="['china_mobile_api_account_form.api_password', {
            normalize: this.$lodash.trim,
            initialValue: chinaMobileApiAccountForm.api_password,
            rules: [{ required: isUseStandardApi, message: '请输入接口密码' },
                    { max: 50, message: '最多50个字符' }
            ]
          }]"
        />
      </a-form-item>

      <a-form-item label="调用限频">
        <a-input-group compact>
          <a-form-item :style="{ display: 'inline-block', width: '55%', marginBottom: 0 }">
            <a-space>
              <a-input-number
                style="width: 135px"
                :min="1"
                :max="10000000"
                v-decorator="['china_mobile_api_account_form.standard_rate_count', {
                  initialValue: chinaMobileApiAccountForm.standard_rate_count === 0 ? undefined : chinaMobileApiAccountForm.standard_rate_count,
                  rules: [{ type: 'integer', message: '请输入 1 - 10000000 之间的整数' }]
                }]"
              />
              <span>次</span>
            </a-space>
          </a-form-item>

          <a-form-item :style="{ display: 'inline-block', width: '40%', marginBottom: 0 }">
            <a-select
              style="width: 140px"
              v-decorator="['china_mobile_api_account_form.standard_rate_time_unit', {
                initialValue: chinaMobileApiAccountForm.standard_rate_time_unit
              }]"
            >
              <a-select-option value="second">
                每秒
              </a-select-option>
              <a-select-option value="minute">
                每分
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-input-group>
      </a-form-item>
    </div>

    <a-form-item label="使用地方移动接口">
      <a-checkbox
        :checked="isUseProvinceApi"
        v-decorator="['china_mobile_api_account_form.is_use_province_api', {
          initialValue: chinaMobileApiAccountForm.is_use_province_api
        }]"
        @change="changeIsUseProvinceApi"
      />
    </a-form-item>

    <!-- 地方移动接口参数显示-->
    <div v-show="isUseProvinceApi">
      <a-form-item label="地方接口所属区域">
        <a-select
          @change="changeProvinceApiType"
          v-decorator="['china_mobile_api_account_form.province_api_type', {
            initialValue: chinaMobileApiAccountForm.province_api_type,
            rules: [{ required: isUseProvinceApi, message: '请选择地方接口所属区域' }
            ]
          }]"
        >
          <a-select-option
            v-for="option in provinceApiTypeOptions"
            :key="option.value"
            :value="option.value"
          >
            {{ option.label }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <a-form-item label="配置参数">
        <a-textarea
          v-decorator="['china_mobile_api_account_form.province_api_config', {
            normalize: this.$lodash.trim,
            initialValue: chinaMobileApiAccountForm.province_api_config,
            rules: [{ required: isUseProvinceApi, message: '请输入配置参数' },
                    { max: 200, message: '最多200个字符' }
            ]
          }]"
          :auto-size="{ minRows: 3, maxRows: 5 }"
          :placeholder="provinceApiConfigPlaceholder"
        />
      </a-form-item>

      <a-form-item label="调用限频">
        <a-input-group compact>
          <a-form-item :style="{ display: 'inline-block', width: '55%', marginBottom: 0 }">
            <a-space>
              <a-input-number
                style="width: 135px"
                :min="1"
                :max="10000000"
                v-decorator="['china_mobile_api_account_form.province_rate_count', {
                  initialValue: chinaMobileApiAccountForm.province_rate_count === 0 ? undefined : chinaMobileApiAccountForm.province_rate_count,
                  rules: [{ type: 'integer', message: '请输入 1 - 10000000 之间的整数' }]
                }]"
              />
              <span>次</span>
            </a-space>
          </a-form-item>

          <a-form-item :style="{ display: 'inline-block', width: '40%', marginBottom: 0 }">
            <a-select
              style="width: 140px"
              v-decorator="['china_mobile_api_account_form.province_rate_time_unit', {
                initialValue: chinaMobileApiAccountForm.province_rate_time_unit
              }]"
            >
              <a-select-option value="second">
                每秒
              </a-select-option>
              <a-select-option value="minute">
                每分
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-input-group>
      </a-form-item>
    </div>
  </div>
</template>

<script>
import { findChinaMobileApiAccountProvinceApiTypeOptions } from '@/api/china_mobile_api_account'

export default {
  name: 'EditChinaMobileApiAccountForm',
  props: {
    chinaMobileApiAccountForm: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isUseStandardApi: this.chinaMobileApiAccountForm.is_use_standard_api,
      isUseProvinceApi: this.chinaMobileApiAccountForm.is_use_province_api, // 是否使用地方接口
      provinceApiType: this.chinaMobileApiAccountForm.province_api_type, // 选择的地方接口
      provinceApiTypeOptions: findChinaMobileApiAccountProvinceApiTypeOptions() // 地方接口类型选项数据
    }
  },
  computed: {
    provinceApiConfigPlaceholder() {
      switch (this.provinceApiType) {
        case 'shanghai':
          return '请依次输入应用编码、秘钥，不同参数用英文逗号隔开'
        default:
          return ''
      }
    }
  },
  methods: {
    changeIsUseStandardApi(e) {
      this.isUseStandardApi = e.target.checked
    },

    changeIsUseProvinceApi(e) {
      this.isUseProvinceApi = e.target.checked
    },

    changeProvinceApiType(value) {
      this.provinceApiType = value
    }
  }
}
</script>
